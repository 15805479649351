import { useEffect } from 'react';
import {
  Route, Routes, Outlet, useLocation,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Modal from 'components/system/Modal';
import Loading from 'components/system/Loading';
import useAccountStore from 'store/accountStore';
import { auth, useAuthState } from 'api/services/firebase';
import { getSavedAccountMode, getSavedTeamId, saveAccountMode } from 'utils/helpers';
import { USER_PROFILE } from 'utils/constants';
import useMe from 'hooks/useMe';
import { resetAllStores } from 'store/advnacedActions';
import 'styles/toast.scss';
import Typography from '@mui/material/Typography';
import Header from './Header';
import Navigation from './Navigation';
import UserVerification from './UserVerification';
import colors from '../../../styles/colors';

const AppPage = ({ isAppLoaded }) => {
  useMe();
  const [user] = useAuthState(auth);
  const isGuest = user === null;
  const isVerified = user?.emailVerified;
  const { pathname } = useLocation();

  const {
    isLoading: accountIsLoading,
    error,
  } = useAccountStore((state) => ({
    isLoading: state.account.isLoading,
    error: state.account.error,
  }));

  useEffect(() => {
    // find a better way to listen to firebase logout action
    if (!user) {
      resetAllStores();
    }
  }, [!!user]);

  useEffect(() => {
    if (user) {
      const teamId = getSavedTeamId(user.uid);
      const activeMode = getSavedAccountMode(user.uid) || USER_PROFILE;
      saveAccountMode(activeMode, user.uid, teamId);
    }
  }, []);

  useEffect(() => {
    document.getElementById('scrollContainer')?.scrollTo(0, 0);
  }, [pathname]);

  // eslint-disable-next-line react/no-unstable-nested-components
  let MainContent = () => <UserVerification />;

  if (isGuest || isVerified) {
    // eslint-disable-next-line react/no-unstable-nested-components
    MainContent = () => (
      <Grid container sx={{ width: '100%', height: 'calc(100% - 80px)' }}>
        <Grid item sx={{ width: '152px' }}>
          <Navigation />
        </Grid>
        <Grid
          item
          className="customScrollBar"
          id="scrollContainer"
          sx={{
            width: 'calc(100% - 152px)',
            height: '100%',
            overflowY: 'auto',
            paddingBottom: '0 !important',
          }}
        >
          <Outlet />
        </Grid>
      </Grid>
    );
  }

  return (
    <Box sx={{
      background: '#060E1F', height: '100%', width: '100%', overflowY: 'hidden',
    }}
    >
      {(!isAppLoaded || (user && user.emailVerified && accountIsLoading)) ? <Loading />
        : (
          <>
            <Header isErrored={error} isVerified={isVerified} />
            {
              error ? (
                <Box
                  sx={{
                    height: 'calc(100% - 80px)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Box>
                    <Typography variant="h5" color={colors.text.primary}>
                      Something went wrong
                    </Typography>
                    <Typography variant="body2" sx={{ color: colors.text.tetriary, padding: '20px 0' }}>
                      Cannot load account details.
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <MainContent />
              )
            }

          </>
        )}
      <Modal />
      <ToastContainer />
    </Box>
  );
};
export default AppPage;
