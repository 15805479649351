import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import useNotebookHostKill from 'hooks/useNotebookHostKill';
import useAccountStore from 'store/accountStore';
import { useModalContext } from 'components/system/ModalContext';
import JobNotebookStopConfirmation from 'components/pages/JobNotebook/JobNotebookStopConfirmation';

const NotebookSection = () => {
  const [canKillNotebook, setCanKillNotebook] = useState(false);
  const { notebookUrl, isNotebookKilling } = useAccountStore(
    (state) => ({
      notebookUrl: state.account.notebookUrl,
      isNotebookKilling: state.account.isNotebookKilling,
    }),
  );

  const setActiveNotebookIsKilling = useAccountStore(
    (state) => state.setActiveNotebookIsKilling,
  );

  useNotebookHostKill(canKillNotebook);

  useEffect(() => {
    if (!notebookUrl) {
      setCanKillNotebook(false);
    }
  }, [notebookUrl]);

  const handleStopNotebook = () => {
    setActiveNotebookIsKilling(true);
    setCanKillNotebook(true);
  };

  const { openModal, closeModal } = useModalContext();

  const handleStop = () => {
    openModal({
      content: (
        <JobNotebookStopConfirmation
          onCancel={closeModal}
          onClose={closeModal}
          onConfirm={handleStopNotebook}
        />
      ),
    });
  };

  return (
    <Grid item sx={{ paddingLeft: '24px' }}>
      <Button
        disabled={!notebookUrl || isNotebookKilling}
        type="secondary"
        variant="outlined"
        onClick={handleStop}
        sx={{ padding: '10px 20px', fontSize: '14px' }}
      >
        Stop
      </Button>
    </Grid>
  );
};

export default NotebookSection;
