import { useQuery } from 'react-query';
import { stopNotebookServer } from 'api/services/notebook';
import { auth, useAuthState } from 'api/services/firebase';
import useAccountStore from '../store/accountStore';

const useNotebookHostKill = (canKill = false) => {
  const [user] = useAuthState(auth);
  const isUserAvailable = !!user;

  const setActiveNotebook = useAccountStore(
    (state) => state.setActiveNotebook,
  );

  const notebookUrl = useAccountStore(
    (state) => state.account.notebookUrl,
  );

  const {
    data,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: 'notebookServerKill',
    queryFn: stopNotebookServer,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    retry: false,
    enabled: isUserAvailable && !!notebookUrl && canKill,
    onSettled: (response, err) => {
      if (!err) {
        setTimeout(() => {
          setActiveNotebook(undefined, false);
        }, 3_000);
      }
    },
  });

  return {
    data,
    isLoading,
    error,
    refetch,
  };
};

export default useNotebookHostKill;
