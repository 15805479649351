import axios from '../axios';

export const getAccountData = () => async () => {
  try {
    const { data: { data } } = await axios.get('/users/me');
    return data;
  } catch (err) {
    console.log('An error occurred while fetching account data', err.message);
    throw err;
  }
};

export const deleteAccount = async () => {
  try {
    const response = await axios.patch('/users/me');
    return response;
  } catch (err) {
    console.log('An error occurred while deleting account data', err.message);
    throw err;
  }
};
