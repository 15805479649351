import { devtools } from 'zustand/middleware';
import { getSavedAccountMode } from 'utils/helpers';
import { roles } from '../constants/permissions';
import { create } from './advnacedActions';

const initialState = {
  activeAccount: {
    sdkToken: '',
    userId: null,
    teamId: null,
    isOwner: false,
    promoStatus: false,
    role: roles.guest,
  },
  account: {
    displayName: '',
    email: '',
    userId: null,
    isLoading: true,
    profiles: [],
    notebookUrl: undefined,
    isNotebookKilling: false,
    team: {},
    user: {},
  },
};

let accountStore = (set, get) => ({
  ...initialState,
  setAccount: (account) => {
    const activeMode = getSavedAccountMode(account.userId) || roles.user;
    const accounts = {
      team: account?.profiles?.find((token) => token.teamId),
      user: account?.profiles?.find((token) => !token.teamId),
    };
    set((state) => ({
      account: {
        ...state.account,
        ...account,
        ...accounts,
      },
      activeAccount: {
        ...accounts[activeMode],
        role: accounts[activeMode]?.teamRole || roles.user,
      },
    }));
  },
  setLoading: (isLoading) => set((state) => ({
    ...state,
    account: {
      ...state.account,
      isLoading,
    },
  })),
  setActiveAccount: (activeAccountType) => {
    set((state) => ({
      activeAccount: {
        ...state.account[activeAccountType],
        role: state.account[activeAccountType]?.teamRole || roles.user,
      },
    }));
  },
  setPartialActiveAccount: (partialAccount) => {
    set((state) => ({
      activeAccount: {
        ...state.activeAccount,
        ...partialAccount,
        role: partialAccount.role || roles.user,
      },
    }));
  },
  setPromoStatus: (promoStatus) => {
    set((state) => ({
      account: { ...state.account, promoStatus },
    }));
  },
  setActiveNotebook: (notebookUrl) => {
    set((state) => ({
      account: { ...state.account, notebookUrl, isNotebookKilling: false },
    }));
  },
  setActiveNotebookIsKilling: (isNotebookKilling) => {
    set((state) => ({
      account: { ...state.account, isNotebookKilling },
    }));
  },
  reset: () => set({ ...initialState }),
});

accountStore = devtools(accountStore, { store: 'accountStore' });

const useAccountStore = create(accountStore);

export default useAccountStore;
